.research-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  width: 100%;
}

.research-parent {
  width: 90%;
  max-width: 1200px;
  background: #ffffff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.research-project {
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 40px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  border-left: 6px solid #f5a107;
}

.research-project:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
}

.project-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.project-image-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  max-width: 600px;
}

.project-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: contain;
}

.project-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

.project-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
}

.project-description {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
}

@media screen and (max-width: 970px) {
  .project-content {
      flex-direction: column;
      align-items: center;
  }

  .project-image-container {
      width: 100%;
      display: flex;
      justify-content: center;
  }

  .project-image {
      width: 90%;
      height: auto;
      border-radius: 10px;
  }

  .project-details {
      width: 100%;
      text-align: center;
  }
}
