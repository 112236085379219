.positions-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  width: 100%;
}

.positions-parent {
  width: 90%;
  max-width: 1200px;
  background: #ffffff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.positions-intro {
  font-size: 18px;
  text-align: justify;
  background: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  line-height: 1.6;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
}

.positions-intro a {
  color: #007bff;
  font-weight: bold;
  text-decoration: none;
}

.positions-intro a:hover {
  text-decoration: underline;
}

.highlight {
  font-weight: bold;
  color: #d9534f;
}

.warning {
  font-weight: bold;
  color: #ff5722;
}

.positions-list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.position-card {
  background: #ffffff;
  border-left: 6px solid #f5a107;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
}

.position-card:hover {
  transform: translateY(-3px);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.12);
}

.position-card h3 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #333;
}

.position-card p {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

@media screen and (max-width: 1200px) {
  .positions-parent {
    width: 95%;
  }
}

@media screen and (max-width: 768px) {
  .positions-parent {
    width: 98%;
    padding: 20px;
  }

  .positions-intro {
    font-size: 16px;
  }

  .position-card h3 {
    font-size: 1.2rem;
  }

  .position-card p {
    font-size: 0.95rem;
  }
}
