.people-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  width: 100%;
}

.people-parent {
  width: 90%;
  max-width: 1200px;
  background: #ffffff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.people-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.person-card {
  background: #f9f9f9;
  border-radius: 12px;
  text-align: center;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
}

.person-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
}

.person-image {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1.2;
  object-fit: contain;
  border-radius: 8px;
  margin-bottom: 10px;
}

.person-info {
  padding: 10px 0;
}

.person-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #007bff;
  text-decoration: none;
}

.person-name:hover {
  text-decoration: underline;
}

.person-title {
  font-size: 1rem;
  color: #666;
  line-height: 1.4;
}

@media screen and (max-width: 1200px) {
.people-parent {
  width: 95%;
}
}

@media screen and (max-width: 768px) {
.people-parent {
  width: 98%;
  padding: 20px;
}

.person-image {
  height: auto;
  aspect-ratio: 1 / 1.2;
}

.person-name {
  font-size: 1rem;
}

.person-title {
  font-size: 0.9rem;
}
}
